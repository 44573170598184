import axios from "axios";
import React, {useState }from "react";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Adduser()
{   
    const notify = () => toast("Thank you , Your form has been submitted successfully!");
    const fail = () => toast("Opps...!! , Something went wrong.");
    const [showHiddenDiv, setShowHiddenDiv] = useState(false);  
    const [loading, setLoading] = useState(false);
    const handleCheckboxChange = (event) => {
      setShowHiddenDiv(event.target.checked);
    };
    // const navigate= useNavigate();    
    const [formvalue, setFormvalue]= useState({
        username: '',
        address: '',
        date: '',
        description: '',
        email: '',
        flooding: false,
        power: false,
        plumbing: false,
        roof: false,
        other: false,
        otherDescription: '',
        electrical: false,
        hvac: false,
        pest: false,
        foundation: false,
      });
    // const [message, setMessage]= useState('');
    const handleInput =(e)=>{

        const { name, value, type, checked } = e.target;
        setFormvalue((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
        
        // setFormvalue({...formvalue, [e.target.name]:e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = {
          username: formvalue.username,
          address: formvalue.address,
          date: formvalue.date,
          description: formvalue.description,
          email: formvalue.email,
          flooding: formvalue.flooding,
          power: formvalue.power,
          plumbing: formvalue.plumbing,
          roof: formvalue.roof,
          otherDescription: formvalue.otherDescription,
          electrical: formvalue.electrical,
          hvac: formvalue.hvac,
          pest: formvalue.pest,
          foundation: formvalue.foundation,
        };
      


        try {
          const res = await axios.post(
            "https://projects.bzbeetech.com/tenant-form/process_form.php",
            formData
          );
      
          if (res.data.success) {

            console.log(res.data.success);
            // Clear the form input fields after submission
            setFormvalue({
              username: "",
              address: "",
              date: "",
              description: "",
              email: "",
              flooding: false,
              power: false,
              plumbing: false,
              roof: false,
              otherDescription: "",
              electrical: false,
              hvac: false,
              pest: false,
              foundation: false,
              other: false,
            });
            notify();
            setLoading(false);
            setShowHiddenDiv(false)
            
          }
        } catch (error) { 
            fail();
        }
      };
      const combined=(e)=>{
        handleCheckboxChange(e)
        handleInput(e);
    }
    return(
        <React.Fragment>

        <ToastContainer />
            <div class="page-wrapper bg-gra-03 p-t-45 p-b-50">
        <div class="wrapper wrapper--w790">
            <div class="card card-5">
            <div class="card-heading">
                    <h2 class="title">Tenant Maintenance Request Form</h2>
                </div>
                <div class="card-body">
                <form onSubmit={handleSubmit}>
                <div className="form-row">
                                <div className="name">Full Name</div>
                                <div className="value">
                                    <div className="input-group">
                                        <input
                                            className="input--style-5"
                                            placeholder="Please Enter Your Full Name"
                                            type="text"
                                            name="username"
                                            value={formvalue.username}
                                            onChange={handleInput}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="name">Address</div>
                                <div className="value">
                                    <div className="input-group">
                                        <input
                                            className="input--style-5"
                                            placeholder="Please Enter Your Address"
                                            type="text"
                                            name="address"
                                            value={formvalue.address}
                                            onChange={handleInput}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="name">Pick a Date</div>
                                <div className="value">
                                    <div className="input-group">
                                        <input
                                            className="input--style-5"
                                            placeholder=""
                                            type="date"
                                            name="date"
                                            value={formvalue.date}
                                            onChange={handleInput}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="name">Write Details</div>
                                <div className="value">
                                    <div className="input-group">
                                        <textarea
                                            className="chck input--style-5"
                                            type="textarea"
                                            name="description"
                                            rows="6"
                                            style={{ width: '100%',lineHeight: 'unset' }}
                                            value={formvalue.description}
                                            onChange={handleInput}
                                            required
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                    {/* <!-- 1 --> */}
                    <div class="checkmain">
                        <div class="check-left">
                        
                            <div className="form-row" style={{ display: 'flex' }}>
                                        <div className="value n_value">
                                            <div className="input-group">
                                                <input
                                                    className="input--style-5 chck"
                                                    type="checkbox"
                                                    name="flooding"
                                                    checked={formvalue.flooding}                               
                                                    onChange={handleInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="name n_name">Flooding/Leaks</div>
                                    </div>



                    <div class="form-row" style={{display: 'flex'}}>
                                
                                <div class="value n_value">
                                    <div class="input-group">
                                    <input
                                                    className="input--style-5 chck"
                                                    type="checkbox"
                                                    name="power"
                                                    checked={formvalue.power}                               
                                                    onChange={handleInput}
                                                />
                                    </div>
                                </div>
                                <div class="name n_name">Power outage</div>
                            </div>
                    {/* <!-- 2 --> */}
                    <div class="form-row" style={{display: 'flex'}}>
                                
                                <div class="value n_value">
                                    <div class="input-group">
                                        <input
                                                    className="input--style-5 chck"
                                                    type="checkbox"
                                                    name="plumbing"
                                                    checked={formvalue.plumbing}                               
                                                    onChange={handleInput}
                                                />
                                    </div>
                                </div>
                                <div class="name n_name">Plumling Problems</div>
                            </div>
                    {/* <!-- 3 --> */}
                    <div class="form-row" style={{display: 'flex'}}>
                                
                                <div class="value n_value">
                                    <div class="input-group">
                                    <input
                                          className="input--style-5 chck"
                                          type="checkbox"
                                          name="roof"
                                          checked={formvalue.roof}                               
                                          onChange={handleInput}
                                      />
                                    </div>
                                </div>
                                <div class="name n_name">Roof Leaks</div>
                            </div>
                            <div className="form-row other" style={{ display: 'flex' }}>
        <div className="value n_value">
          <div className="input-group">
            <input
              className="input--style-5 chck"
              type="checkbox"
              name="other"
              checked={formvalue.other}
              onChange={combined}
            />
          </div>
        </div>
        <div className="name n_name">Other</div>
      </div>
                        </div>
                        <div class="check-right">
                    {/* <!-- 4 --> */}
                    <div class="form-row" style={{display: 'flex'}}>
                        
                        <div class="value n_value">
                            <div class="input-group">
                        <input
                              className="input--style-5 chck"
                              type="checkbox"
                              name="electrical"
                              checked={formvalue.electrical}                              
                              onChange={handleInput}
                          />
                            </div>
                        </div>
                        <div class="name n_name">Electrical Wiring Problems</div>
                    </div>
                    {/* <!-- 5 --> */}
                    <div class="form-row" style={{display: 'flex'}}>
                        
                        <div class="value n_value">
                            <div class="input-group">
                            <input
                                    className="input--style-5 chck"
                                    type="checkbox"
                                    name="hvac"
                                    checked={formvalue.hvac}                                
                                    onChange={handleInput}
                                />
                            </div>
                        </div>
                        <div class="name n_name">HVAC System Malfunctions</div>
                    </div>
                    {/* <!-- 6 --> */}
                    <div class="form-row" style={{display: 'flex'}}>
                        
                        <div class="value n_value">
                            <div class="input-group">
                            <input
                                    className="input--style-5 chck"
                                    type="checkbox"
                                    name="pest"
                                    checked={formvalue.pest}                                   
                                    onChange={handleInput}
                                />
                            </div>
                        </div>
                        <div class="name n_name">Pest Infestations</div>
                    </div>
                    <div class="form-row" style={{display: 'flex'}}>
                       
                        <div class="value n_value">
                            <div class="input-group">
                            <input
                                  className="input--style-5 chck"
                                  type="checkbox"
                                  name="foundation"
                                  checked={formvalue.foundation}                                
                                  onChange={handleInput}
                              />
                            </div>
                        </div>
                        <div class="name n_name">Foundation Issues</div>
                    </div>
                        </div>

                    </div>
                    {showHiddenDiv && (
        <div className="form-row answer" id="hiddenDiv">
          <div className="value">
            <div className="input-group">
            <textarea
                                            className="chck input--style-5"
                                            type="textarea"
                                            name="otherDescription"
                                            rows="4"
                                            style={{ width: '100%',lineHeight: 'unset' }}
                                            value={formvalue.otherDescription}
                                            onChange={handleInput}
                                            required
                                        ></textarea>
                                   
            </div>
          </div>
        </div>
      )}
                        <div class="form-row" style={{marginTop: '15px'}}>
                            <div class="name">Send To Email</div>
                            <div class="value">
                                <div class="input-group">
                                <input
                                            className="input--style-5"
                                            placeholder="Enter Recipient Email"
                                            type="email"
                                            name="email"
                                            value={formvalue.email}
                                            onChange={handleInput}
                                            required
                                        />

                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: 'end'}}>
                            <button class="btn btn--radius-2 btn--red" type="submit" disabled={loading} style={{cursor:loading?"not-allowed":"pointer"}} >{loading ? "Sending..." : "Submit Details"}</button>
                        </div>
                </form>
                </div>
            </div>
        </div>
    </div>
        </React.Fragment>
    );
}
export default Adduser;