
import './App.css';
import Tenant_form from './Tenant_form';

function App() {
  return (
   <>
   
   <Tenant_form/>
   </>
  );
}

export default App;
